import { Link } from '@reach/router';
import { Dialog, DialogActions, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { navigate } from 'gatsby';
import React, { memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContext from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import Button from '../shared/Button';
import Logo from '../shared/Logo';
import CustomButton from '../CustomButton';

const useStyles = makeStyles(() => ({
  '@global': {
    '.MuiOutlinedInput-root': {
      borderRadius: '8px !important',
    },
    '.css-hgttjy': {
      height: '220px !important',
    },
    '.css-1qudts3-MuiPaper-root-MuiDialog-paper': {
      borderRadius: '8px !important',
    },
    '.MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: '0 !important',
    },
    '.css-zw3mfo-MuiModal-root-MuiDialog-root': {
      zIndex: '1500 !important',
    },
  },
}));

const Hero = () => {
  useStyles();

  const [modalOpen, setModalOpen] = useState(true);

  const { t } = useTranslation();
  const { emitter, events } = useContext(ModalContext);
  const { user, loading } = useContext(UserContext);

  const handleLogin = () => emitter.emit(events.AUTH_MODAL);

  const handleGotoApp = () => navigate('/app/dashboard');

  const onClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = () => {
    navigate('https://aksel.co.id/');
  };

  return (
    <div className="flex flex-col items-center md:flex-row">
      <Link to="/">
        <span className="sr-only">Aksel CV Maker</span>
        <Logo size="256px" />
      </Link>

      <div className="mt-12 md:mt-0 md:ml-12">
        <h1 className="text-5xl font-bold">{t('shared.appName')}</h1>

        <div className="mt-12">
          {user ? (
            <Button
              onClick={handleGotoApp}
              isLoading={loading}
              className="mx-auto md:mx-0"
            >
              {t('landing.hero.goToApp')}
            </Button>
          ) : (
            <Button
              onClick={handleLogin}
              isLoading={loading}
              className="mx-auto md:mx-0"
            >
              {t('shared.buttons.login')}
            </Button>
          )}
        </div>
      </div>

      <Dialog
        open={modalOpen}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <div style={{ padding: '0 16px' }}>
          <Typography
            variant="sectionHeader"
            style={{
              marginTop: '32px',
              padding: '0 100px',
              textAlign: 'center',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '20px',
              lineHeight: '140%',
              display: 'block',
            }}
          >
            CV Maker Aksel ada tampilan baru!
          </Typography>
          <Typography
            variant="p"
            style={{
              textAlign: 'center',
              marginTop: '12px',
              marginBottom: '16px',
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '14px',
              lineHeight: '140%',
              letterSpacing: '0.1px',
              display: 'block',
              padding: '0 40px',
            }}
          >
            Lebih lengkap dan semakin memudahkan kamu dalam membuat CV
          </Typography>

          <DialogActions
            style={{
              justifyContent: 'center',
              marginBottom: '16px',
              flexDirection: 'column',
            }}
          >
            <CustomButton
              style={{ padding: '6px 0', width: '240px' }}
              onClick={handleSubmit}
              fullWidth
            >
              Go to New Version
            </CustomButton>
            <CustomButton
              variant="outlined"
              color="secondary"
              style={{
                padding: '6px 0',
                width: '240px',
                marginTop: '8px',
                borderColor: '#fff',
              }}
              onClick={onClose}
              fullWidth
            >
              Stay Here
            </CustomButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};

export default memo(Hero);
